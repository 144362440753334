import type { SpaceOperationsQueryType } from '@confluence/entity-operations';

const canPerform = (data: SpaceOperationsQueryType, operation: string, targetType: string) => {
	const operations = data?.space?.operations ?? [];
	return operations.some((op) => op?.operation === operation && op?.targetType === targetType);
};

export const canCreatePage = (data: SpaceOperationsQueryType) => canPerform(data, 'create', 'page');

export const canCreateBlog = (data: SpaceOperationsQueryType) =>
	canPerform(data, 'create', 'blogpost');
