import { useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	ExperienceTrackerContext,
	ONBOARDING_STORAGE_ENFORCEMENT_BLOCK_CREATE_MODAL,
} from '@confluence/experience-tracker';

import { useGetCurrentStorageUsage } from './useGetCurrentStorageUsage';
import { useIsStorageEnforcementEligible } from './useIsStorageEnforcementEligible';
import { useBlockCreateModalStore } from './useBlockCreateModalStore';

interface UseHardStorageEnforcementProps {
	source: string; // A unique identifier used to track what triggered the modal.
}

interface UseHardStorageEnforcement {
	/* Boolean to determine if creation should be blocked */
	shouldBlockCreate: boolean;
	/* Function that wraps onClick handlers to prevent them from firing */
	enforceStorageLimit: <T extends (...args: any) => any>(
		callback: T,
	) => (...args: Parameters<T>) => ReturnType<T> | undefined;
}

// A hook with helper functions to show the storage enforcement modal.
export const useHardStorageEnforcement = ({
	source,
}: UseHardStorageEnforcementProps): UseHardStorageEnforcement => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isEligibleUser = useIsStorageEnforcementEligible();
	const { isStorageEnforcementGracePeriodComplete } = useGetCurrentStorageUsage({
		skip: !isEligibleUser,
	});
	const [, { showBlockCreateModal }] = useBlockCreateModalStore();

	const shouldBlockCreate = isEligibleUser && Boolean(isStorageEnforcementGracePeriodComplete);

	const enforceStorageLimit: UseHardStorageEnforcement['enforceStorageLimit'] = useCallback(
		(callback) =>
			(...args) => {
				if (shouldBlockCreate) {
					// If the callback is a DOM event (e.g. click, keydown/keyup)
					// handler, prevent the default action (e.g. navigating to an href):
					const event = args?.[0];
					if (event && typeof event.preventDefault === 'function') {
						event.preventDefault();
					}

					experienceTracker.start({
						name: ONBOARDING_STORAGE_ENFORCEMENT_BLOCK_CREATE_MODAL,
					});

					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							action: 'render',
							actionSubject: 'hardStorageEnforcement',
							actionSubjectId: 'enforceStorageLimit',
							source: source || 'onboarding',
						},
					}).fire();

					showBlockCreateModal(source);
					return;
				}

				return callback.apply(undefined, args);
			},
		[createAnalyticsEvent, experienceTracker, shouldBlockCreate, showBlockCreateModal, source],
	);

	return {
		shouldBlockCreate,
		enforceStorageLimit,
	};
};
