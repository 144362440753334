import gql from 'graphql-tag';

export const GetCurrentStorageUsageQuery = gql`
	query GetCurrentStorageUsageQuery {
		storage {
			bytesUsed
			isStorageEnforcementGracePeriodComplete
			gracePeriodEndDate
		}
	}
`;
