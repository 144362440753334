import { LoadableAfterPaint } from '@confluence/loadable';

export const ContextualCreateContentPopoverLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContextualCreatePopover" */ '../src/CreateContentPopover'
			)
		).ContextualCreateContentPopover,
});
