//Add entry points here and import only your entry point to support treeshaking

export const PAGE_EDIT_BUTTON_CLICK = 'PAGE_EDIT_BUTTON_CLICK';
export const BLOG_EDIT_BUTTON_CLICK = 'BLOG_EDIT_BUTTON_CLICK';
export const SPACE_EDIT_BUTTON_CLICK = 'SPACE_EDIT_BUTTON_CLICK';
export const PAGE_EDIT_SHORTCUT_E = 'PAGE_EDIT_SHORTCUT_E';
export const BLOG_EDIT_SHORTCUT_E = 'BLOG_EDIT_SHORTCUT_E';
export const SPACE_EDIT_SHORTCUT_E = 'SPACE_EDIT_SHORTCUT_E';
export const NAVIGATION_RECENT_DROPDOWN_EDIT_BUTTON_CLICK =
	'NAVIGATION_RECENT_DROPDOWN_EDIT_BUTTON_CLICK';
export const PAGECARD_EDIT_BUTTON_CLICK = 'PAGECARD_EDIT_BUTTON_CLICK';
export const PAGECARD_DRAFT_EDIT_BUTTON_CLICK = 'PAGECARD_DRAFT_EDIT_BUTTON_CLICK';
export const QUICK_ACTIONS_EDIT_BUTTON_CLICK = 'QUICK_ACTIONS_EDIT_BUTTON_CLICK';
export const EDIT_BUTTON_CLICK = 'EDIT_BUTTON_CLICK';
export const EDIT_E_SHORTCUT = 'SHORTCUT_E_TO_EDIT';

// Create
export const CREATE_PAGE_SHORTCUT = 'CREATE_PAGE_SHORTCUT';
export const CREATE_NAV_BUTTON_CLICK = 'CREATE_NAV_BUTTON_CLICK';
export const CREATE_PAGETREE_BLOG_BUTTON_CLICK = 'CREATE_PAGETREE_BLOG_BUTTON_CLICK';
export const CREATE_PAGETREE_CHILDPAGE_BUTTON_CLICK = 'CREATE_PAGETREE_CHILDPAGE_BUTTON_CLICK';
export const CREATE_PAGETREE_PAGE_BUTTON_CLICK = 'CREATE_PAGETREE_PAGE_BUTTON_CLICK';

export const InitialLoadEditSources = [
	QUICK_ACTIONS_EDIT_BUTTON_CLICK,
	PAGECARD_EDIT_BUTTON_CLICK,
	PAGECARD_DRAFT_EDIT_BUTTON_CLICK,
	NAVIGATION_RECENT_DROPDOWN_EDIT_BUTTON_CLICK,
];
