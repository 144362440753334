export const useIsCreationDropdownEnabled = () => {
	/**
	 * DO NOT MODIFY THIS METHOD.
	 * This method has been refactored to return true as part of an effort to remove LaunchDarkly feature flags.
	 * Returning true avoids regressions.
	 * This method will be removed when ALL dependent feature flags have been removed.
	 * This work is being tracked in https://hello.jira.atlassian.cloud/browse/CTIA-2808
	 */
	return true;
};
