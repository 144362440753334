import { createStore, createHook } from 'react-sweet-state';

interface BlockCreateModalState {
	isActive: boolean;
	source: string;
}

const initialState: BlockCreateModalState = {
	isActive: false,
	source: '',
};

const NAME = 'BLOCK_CREATE_MODAL_STORE';

const BlockCreateModalStore = createStore({
	name: NAME,
	initialState,
	actions: {
		showBlockCreateModal:
			(source) =>
			({ setState }) => {
				setState({ isActive: true, source });
			},
		onBlockCreateModalClose:
			() =>
			({ setState }) => {
				setState({ isActive: false, source: '' });
			},
	},
});

export const useBlockCreateModalStore = createHook(BlockCreateModalStore);
