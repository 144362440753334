import gql from 'graphql-tag';

import { SpaceOperationsFragment } from './EntityOperationsFragment.fragment';

export const SpaceOperationsQuery = gql`
	query SpaceOperationsQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			...SpaceOperationsFragment
		}
	}
	${SpaceOperationsFragment}
`;
