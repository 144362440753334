import type { RoutesContextType } from '@confluence/route-manager';
import { getSingleParam, createRouteParamHook } from '@confluence/route-manager';
import { CREATE_CONTENT } from '@confluence/named-routes';
import {
	JIRA_IMPORT_TOOL_QUERY_PARAM,
	CLOUD_ID,
	PROJECT_UUID,
	INSERT_TEMPLATE_WITH_KEY_QUERY_PARAM,
} from '@confluence/whiteboard-utils';

import { PaywallStatus } from '../createContentInputTypes';

const queryParamKeys = {
	parentPageId: 'parentPageId',
	title: 'title',
	draftId: 'draftId',
	linkCreation: 'linkCreation',
	fromPageId: 'fromPageId', // used by createpage.action endpoint.
	spaceKey: 'spaceKey',
	source: 'source',
	withFallback: 'withFallback',
	paywallStatus: 'paywallStatus',
	deactivationIdentifier: 'deactivationIdentifier',
	openJiraImportTool: JIRA_IMPORT_TOOL_QUERY_PARAM,
	cloudId: CLOUD_ID,
	projectUuid: PROJECT_UUID,
	insertTemplateWithKey: INSERT_TEMPLATE_WITH_KEY_QUERY_PARAM,
	smartLinkCreateSource: 'smartLinkCreateSource',
	smartLinkEmbedUrl: 'smartLinkEmbedUrl',
	restoreVersionContentId: 'restoreVersionContentId',
};

const SUPPORTED_CONTENT_TYPES = [
	'page',
	'blog',
	'whiteboard',
	'database',
	'smartLinkEmbed',
] as const;
export type CreateRouteSupportedContentType = (typeof SUPPORTED_CONTENT_TYPES)[number];

type CreateBlog = {
	contentType: 'blog';
	source: string;
	spaceKey?: string;
	withFallback?: true;
};
type CreatePage = {
	contentType: 'page';
	source: string;
	/**
	 * Parent page of created page
	 */
	parentPageId?: string;
	/**
	 * Title of created page
	 */
	title?: string;
	/**
	 * Redirect to edit page of this draft id.
	 * If id does not match any content, fallback to page creation.
	 */
	draftId?: string;
	/**
	 * Sets link creation query parameter for undefined links.
	 * At least for edit-v2 it passes the query parameter value to the editor.
	 * Only value that the editor looks for is "true", all other values are considered falsy.
	 */
	linkCreation?: 'true';
	/**
	 * Space key of target space to create page in
	 */
	spaceKey?: string;
	withFallback?: true;
};
export type CreateWhiteboard = {
	contentType: 'whiteboard';
	source: string;
	spaceKey?: string;
	parentPageId?: string;
	withFallback?: true;
	/**
	 * Whiteboards have a creation limit paywall. non-premium users can only have 3 activate whiteboards (this could change over time - check with CTIA team if this seems out of date).
	 * If a new one is created after that, you need to exchange another board for it to become active. (deactivationIdentifier + paywallStatus=ACTIVE handles this)
	 * if no paywall status is set, it will become `UNSET` by default, which will should an upsell when the creator views the board.
	 */
	paywallStatus?: PaywallStatus;
	deactivationIdentifier?: string;
	openJiraImportTool?: true;
	cloudId?: string;
	projectUuid?: string;
	insertTemplateWithKey?: string;
	/**
	 * Whiteboards support historical versions. This content id corresponds to the content with an external version id from canvas.
	 * If this content is present in the query param, we are trying to restore a historical version as a new whiteboard
	 */
	restoreVersionContentId?: string;
	title?: string;
};

type CreateDatabase = {
	contentType: 'database';
	source: string;
	spaceKey?: string;
	parentPageId?: string;
	withFallback?: true;
};

type CreateSmartLinkEmbed = {
	contentType: 'smartLinkEmbed';
	source: string;
	spaceKey?: string;
	parentPageId?: string;
	withFallback?: true;
	smartLinkCreateSource?: string;
	smartLinkEmbedUrl?: string;
};

type CreateContentOptions =
	| CreatePage
	| CreateBlog
	| CreateWhiteboard
	| CreateDatabase
	| CreateSmartLinkEmbed;

export const buildCreationLink = (options: CreateContentOptions): string => {
	const queryParams = {};
	if ('spaceKey' in options && options.spaceKey) {
		queryParams[queryParamKeys.spaceKey] = options.spaceKey;
	}
	if ('parentPageId' in options && options.parentPageId) {
		queryParams[queryParamKeys.parentPageId] = options.parentPageId;
	}
	if ('title' in options && options.title) {
		queryParams[queryParamKeys.title] = options.title;
	}
	if ('draftId' in options && options.draftId) {
		queryParams[queryParamKeys.draftId] = options.draftId;
	}
	if ('linkCreation' in options && options.linkCreation) {
		queryParams[queryParamKeys.linkCreation] = options.linkCreation;
	}
	if ('withFallback' in options && options.withFallback) {
		queryParams[queryParamKeys.withFallback] = options.withFallback;
	}
	if ('source' in options && options.source) {
		queryParams[queryParamKeys.source] = options.source;
	}
	if ('paywallStatus' in options && options.paywallStatus) {
		queryParams[queryParamKeys.paywallStatus] = options.paywallStatus;
	}
	if ('deactivationIdentifier' in options && options.deactivationIdentifier) {
		queryParams[queryParamKeys.deactivationIdentifier] = options.deactivationIdentifier;
	}
	if (JIRA_IMPORT_TOOL_QUERY_PARAM in options && options.openJiraImportTool) {
		queryParams[queryParamKeys.openJiraImportTool] = options.openJiraImportTool;
	}
	if (CLOUD_ID in options && options.cloudId) {
		queryParams[queryParamKeys.cloudId] = options.cloudId;
	}
	if (PROJECT_UUID in options && options.projectUuid) {
		queryParams[queryParamKeys.projectUuid] = options.projectUuid;
	}
	if (INSERT_TEMPLATE_WITH_KEY_QUERY_PARAM in options && options.insertTemplateWithKey) {
		queryParams[queryParamKeys.insertTemplateWithKey] = options.insertTemplateWithKey;
	}
	if ('smartLinkCreateSource' in options && options.smartLinkCreateSource) {
		queryParams[queryParamKeys.smartLinkCreateSource] = options.smartLinkCreateSource;
	}
	if ('smartLinkEmbedUrl' in options && options.smartLinkEmbedUrl) {
		queryParams[queryParamKeys.smartLinkEmbedUrl] = options.smartLinkEmbedUrl;
	}
	if ('restoreVersionContentId' in options && options.restoreVersionContentId) {
		queryParams[queryParamKeys.restoreVersionContentId] = options.restoreVersionContentId;
	}

	return CREATE_CONTENT.toUrl({ contentType: options.contentType }, { query: queryParams });
};

const useContentType = createRouteParamHook('contentType');
/**
 * Gets the current `contentType` route parameter, and returns it if it's a supported type.
 * If not, returns undefined.
 */
export const useCreateRouteSupportedContentType = ():
	| CreateRouteSupportedContentType
	| undefined => {
	const contentType = useContentType();
	if (SUPPORTED_CONTENT_TYPES.includes(contentType as CreateRouteSupportedContentType)) {
		return contentType as CreateRouteSupportedContentType;
	}
	return undefined;
};

type ParseQueryParamsResponse = {
	parentPageId?: string;
	title?: string;
	draftId?: string;
	linkCreation?: string;
	spaceKey?: string;
	withFallback?: true;
	source?: string;
	paywallStatus?: PaywallStatus;
	deactivationIdentifier?: string;
	openJiraImportTool?: boolean;
	cloudId?: string;
	projectUuid?: string;
	insertTemplateWithKey?: string;
	smartLinkCreateSource?: string;
	smartLinkEmbedUrl?: string;
	restoreVersionContentId?: string;
};

type QueryParams = ReturnType<RoutesContextType['getQueryParams']>;

export const parseQueryParams = (
	queryParams: QueryParams,
	isJswWhiteboardExperimentEnabled = false,
): ParseQueryParamsResponse => {
	const spaceKey = getSingleParam(queryParams, queryParamKeys.spaceKey);
	const parentPageId = getSingleParam(queryParams, queryParamKeys.parentPageId);
	const title = getSingleParam(queryParams, queryParamKeys.title);
	const draftId = getSingleParam(queryParams, queryParamKeys.draftId);
	const linkCreation = getSingleParam(queryParams, queryParamKeys.linkCreation);
	const fromPageId = getSingleParam(queryParams, queryParamKeys.fromPageId);
	const withFallback = getSingleParam(queryParams, queryParamKeys.withFallback) ? true : undefined;
	const source = getSingleParam(queryParams, queryParamKeys.source);
	const deactivationIdentifier = getSingleParam(queryParams, queryParamKeys.deactivationIdentifier);
	const paywallStatus =
		PaywallStatus[getSingleParam(queryParams, queryParamKeys.paywallStatus) || ''];
	const openJiraImportTool =
		isJswWhiteboardExperimentEnabled &&
		getSingleParam(queryParams, queryParamKeys.openJiraImportTool)
			? true
			: undefined;
	const cloudId = isJswWhiteboardExperimentEnabled
		? getSingleParam(queryParams, queryParamKeys.cloudId)
		: undefined;
	const projectUuid = isJswWhiteboardExperimentEnabled
		? getSingleParam(queryParams, queryParamKeys.projectUuid)
		: undefined;
	const insertTemplateWithKey =
		getSingleParam(queryParams, queryParamKeys.insertTemplateWithKey) ?? undefined;
	const smartLinkCreateSource = getSingleParam(queryParams, queryParamKeys.smartLinkCreateSource);
	const smartLinkEmbedUrl = getSingleParam(queryParams, queryParamKeys.smartLinkEmbedUrl);
	const restoreVersionContentId = getSingleParam(
		queryParams,
		queryParamKeys.restoreVersionContentId,
	);

	return {
		spaceKey,
		parentPageId: parentPageId || fromPageId, // prefer new parameter over old parameter
		title,
		draftId,
		linkCreation,
		withFallback,
		source,
		paywallStatus,
		deactivationIdentifier,
		openJiraImportTool,
		cloudId,
		projectUuid,
		insertTemplateWithKey,
		smartLinkCreateSource,
		smartLinkEmbedUrl,
		restoreVersionContentId,
	};
};

export enum ExperienceResolution {
	ABORT,
	SUCCEED,
}
